// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

export const mininumBufferSize = 10;
export const defaultBufferSize = 60;
export const minimumRoadNetworkBufferSize = 10;
export const defaultRoadNetworkBufferSize = 10;
export const opsDailyCreditCapacity = 150;
export const rasterizationCredits = 5;

export const defaultLazPtSize = 1;
export const defaultDxfPtSize = 8;

export const projectTypes = ['standard', 'utilities'];

export const standardBasePackages = ['2D', '2D + Topo', 'Topo'];
export const utilitiesBasePackages = ['Underground Basic', 'Underground Heavy', 'Microtrenched'];
export const utilitiesPackagesSubtypes = ['Underground (2D)', 'Aerial (3D)', 'A la carte'];

export const topoIntervals = [0.25, 0.5, 1, 2, 5, 10];

export const classification6Classes = [
  { name: 'Non-Ground', color: '#26C1FB', classId: 1 },
  { name: 'Ground', color: '#9BBCC8', classId: 2 },
  { name: 'Buildings', color: '#B2AFAB', classId: 3 },
  { name: 'Cars', color: '#FAA738', classId: 4 },
  { name: 'Vegetation', color: '#93BB25', classId: 5 },
  { name: 'Poles', color: '#788898', classId: 6 },
];

export const classification2Classes = [
  { name: 'Non-Ground', color: '#26C1FB', classId: 1 },
  { name: 'Ground', color: '#9BBCC8', classId: 2 },
];

export const classification6ClassesNew = [
  { name: 'Unclassified', color: '#568CA0', classId: 1 },
  { name: 'Ground', color: '#9BBBC8', classId: 2 },
  { name: 'Vegetation', color: '#93BB25', classId: 5 },
  { name: 'Buildings', color: '#BB8A49', classId: 6 },
  { name: 'Wires', color: '#EEEE31', classId: 14 },
  { name: 'Pole and Telco Towers', color: '#0200FF', classId: 15 },
];

export const defaultExpressAILayers = ['B-OVERHANG', 'R-PAVEMARK-LINE', 'R-ROAD', 'V-VEGETATION'];

export const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const longMonthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const billingMethods = ['Pre-Pay Annually', 'Pre-Pay Quarterly', 'Pre-Pay Monthly', 'Monthly Usage Invoice', 'Project by Project'];

export const pricingDescription = [
  {
    name: 'no_commit',
    description: 'Pay as you go',
    displayName: 'Pay as you go',
  },
  {
    name: 'enterprise_pre_commit',
    description: 'Pay as you go',
    displayName: 'Enterprise Pre-commit',
  },
  {
    name: 'buy_monthly',
    description: 'Prepay 10% Off',
    displayName: 'Prepay Monthly',
  },
  {
    name: 'buy_quarterly',
    description: 'Prepay 20% Off',
    displayName: 'Prepay Quarterly',
  },
  {
    name: 'buy_upfront',
    description: 'Prepay 30% Off',
    displayName: 'Prepay Upfront',
  },
];

export const getLineWeights = (): number[] => {
  const lineWeight: number[] = [];
  for (let i = -3; i <= 100; i += 1) {
    lineWeight.push(i);
  }
  return lineWeight;
};

export const pricingTierLimits = [
  {
    lowerLimit: 0,
    upperLimit: 100,
  },
  {
    lowerLimit: 100,
    upperLimit: 200,
  },
  {
    lowerLimit: 200,
    upperLimit: 300,
  },
  {
    lowerLimit: 300,
    upperLimit: 600,
  },
  {
    lowerLimit: 600,
    upperLimit: 1200,
  },
  {
    lowerLimit: 1200,
    upperLimit: 3600,
  },
  {
    lowerLimit: 3600,
    upperLimit: 5000,
  },
  {
    lowerLimit: 5000,
    upperLimit: 6000,
  },
  {
    lowerLimit: 6000,
    upperLimit: 7000,
  },
  {
    lowerLimit: 7000,
    upperLimit: 8000,
  },
  {
    lowerLimit: 8000,
    upperLimit: 9000,
  },
  {
    lowerLimit: 9000,
    upperLimit: 10000,
  },
  {
    lowerLimit: 10000,
    upperLimit: 11000,
  },
  {
    lowerLimit: 11000,
    upperLimit: 12000,
  },
  {
    lowerLimit: 12000,
    upperLimit: 13000,
  },
  {
    lowerLimit: 13000,
    upperLimit: 14000,
  },
  {
    lowerLimit: 14000,
    upperLimit: 15000,
  },
  {
    lowerLimit: 15000,
    upperLimit: 16000,
  },
  {
    lowerLimit: 16000,
    upperLimit: 17000,
  },
  {
    lowerLimit: 17000,
    upperLimit: 18000,
  },
  {
    lowerLimit: 18000,
    upperLimit: 19000,
  },
  {
    lowerLimit: 19000,
    upperLimit: 20000,
  },
  {
    lowerLimit: 20000,
    upperLimit: 25000,
  },
  {
    lowerLimit: 25000,
    upperLimit: 30000,
  },
  {
    lowerLimit: 30000,
    upperLimit: 35000,
  },
  {
    lowerLimit: 35000,
    upperLimit: 50000,
  },
  {
    lowerLimit: 50000,
    upperLimit: 75000,
  },
  {
    lowerLimit: 75000,
    upperLimit: 100000,
  },
  {
    lowerLimit: 100000,
    upperLimit: 150000,
  },
  {
    lowerLimit: 150000,
    upperLimit: 10000000,
  },
];
